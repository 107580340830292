import React from 'react'
import bg from "../assets/bg-ban.png";
import ScrollAnimation from 'react-animate-on-scroll';

const Social = () => {
    return (
        <div className='text-center my-40'>
            <div className=''>
                <h1 className='lg:text-[5rem] text-[3rem]'>Become a Martian</h1>
                <p className='text-xl text-[#afafaf] '>Join our vibrant community, interact with fellow Martians and get latest updates</p>
            </div>
            <ScrollAnimation animateIn='fadeIn'>
                <div className='flex justify-center my-32 p-6  p-20'  style={{ backgroundImage: `url(${bg})`,backgroundColor: "rgb(250 252 254)", backgroundRepeat : 'repeat',backgroundSize : '100px' }}>
                    <div className='flex flex-col items-center shadow-lg p-5 w-64 mx-4 bg-white rounded-xl'>
                        <p>DISCORD</p>
                        <svg width="97" height="96" viewBox="0 0 97 96" fill="none" xmlns="http://www.w3.org/2000/svg"><ellipse cx="48.5459" cy="47.6384" rx="47.7609" ry="47.5781" fill="#F5F6F6"></ellipse><path fillRule="evenodd" clipRule="evenodd" d="M40.6065 28.3744C37.4176 29.0441 34.092 30.0846 31.6135 31.1883C29.8354 31.98 25.9418 39.8591 24.4561 45.6721C23.2818 50.2668 22.6865 58.7642 23.4073 60.6425C23.6416 61.253 29.3008 64.5168 32.2928 65.7669C36.0807 67.3494 36.2231 67.358 37.0944 66.0542C38.8897 63.3675 39.1014 62.7908 38.4036 62.4883C36.2558 61.5572 34.8655 60.7439 34.9681 60.4787C35.2034 59.8708 35.7756 59.8106 36.8643 60.2793C44.0942 63.3912 52.7295 63.4667 59.9721 60.4813C61.6288 59.7984 61.6586 59.7947 62.1232 60.2205C62.6494 60.7028 61.8309 61.2868 58.9928 62.4537C58.2612 62.7546 58.2961 62.8957 59.6407 65.0774C61.1508 67.5274 60.8412 67.4811 64.8555 65.8571C67.8955 64.627 73.783 61.2457 73.9754 60.6192C74.0928 60.2368 74.2357 57.7835 74.2357 56.1501C74.2357 47.9448 72.0044 40.3601 67.4382 33.0434C66.275 31.1794 64.4539 30.2711 59.2193 28.9439C55.8058 28.0784 55.895 28.0618 55.1188 29.7075C54.5168 30.9837 54.4876 31.0054 53.5643 30.8611C51.0663 30.4705 46.5162 30.4691 43.8623 30.8582C42.8982 30.9995 42.8602 30.9749 42.3488 29.8806C41.8347 28.7804 41.4677 28.2542 41.2251 28.2694C41.1319 28.2753 40.8535 28.3225 40.6065 28.3744ZM41.9497 44.9072C45.0671 46.366 45.7641 50.8385 43.2834 53.4642C40.8093 56.0831 36.5608 54.6003 35.764 50.84C34.9077 46.7988 38.5424 43.3127 41.9497 44.9072ZM59.0301 44.97C62.0605 46.4676 62.7232 50.8727 60.3002 53.4129C57.7158 56.1224 53.5457 54.6362 52.7205 50.7115C51.8784 46.7059 55.6318 43.2904 59.0301 44.97Z" fill="black"></path></svg>
                        <p>Join the Martian Discord community</p>
                    </div>
                    <div className='flex flex-col items-center shadow-lg p-5 w-64 mx-4 bg-white rounded-xl'>
                        <p>TWITTER</p>
                        <svg width="97" height="96" viewBox="0 0 97 96" fill="none" xmlns="http://www.w3.org/2000/svg"><ellipse cx="48.6947" cy="47.6383" rx="47.7609" ry="47.5781" fill="#F5F6F6"></ellipse><path fillRule="evenodd" clipRule="evenodd" d="M55.6659 29.6092C50.9181 30.607 47.579 35.4089 48.4391 40.0024C48.5708 40.706 48.5328 40.729 47.4212 40.6196C41.527 40.0393 36.1692 37.5443 31.8805 33.3827C31.314 32.833 30.752 32.3458 30.6314 32.2999C27.3459 31.0508 28.0339 39.6112 31.4626 42.6425C32.2812 43.3662 32.1785 43.4312 30.7332 43.105C29.6402 42.8583 29.4486 42.8539 29.0329 43.0659C28.4509 43.3628 28.1081 44.2463 28.3764 44.7578C28.4174 44.8356 28.5145 45.0829 28.5925 45.3071C29.4765 47.8522 31.6588 50.0352 34.2668 50.9835C35.3644 51.3826 35.2841 51.5012 33.8092 51.6604C29.8205 52.0908 34.3497 57.1099 39.412 57.8692C40.0409 57.9636 39.8578 58.2066 38.5381 59.0291C35.8261 60.7191 32.2053 61.8233 29.3752 61.8233C26.3317 61.8233 30.1233 64.11 34.9742 65.2C51.2544 68.858 66.5533 56.5608 66.5542 39.8159L66.5543 38.5658L66.7822 38.3478C66.9075 38.2279 67.2799 37.9096 67.6097 37.6404C69.512 36.0882 70.3949 34.84 69.806 34.5355C69.5166 34.3859 69.4773 34.3906 68.1703 34.733C66.4345 35.1877 65.9862 35.1517 66.697 34.6149C67.8731 33.7268 69.2458 31.9722 69.2458 31.3573C69.2458 30.709 68.8565 30.6973 67.4041 31.302C66.8622 31.5276 66.018 31.8235 65.5282 31.9595C64.0644 32.3661 64.1986 32.3742 63.606 31.8439C61.3978 29.8683 58.4207 29.0303 55.6659 29.6092Z" fill="black"></path></svg>
                        <p>Follow us on Twitter</p>
                    </div>
                </div>
            </ScrollAnimation>

        </div>
    )
}

export default Social