import React from "react";
import frm from "../assets/frm.png";
import acc from "../assets/acc.png";
import { NavLink } from "react-router-dom";
const Banner = () => {
  return (
    <div className="flex lg:flex-row flex-col-reverse justify-between items-center">
      <div className="flex flex-col lg:items-start md:items-start items-center lg:text-left md:text-left text-center items-center lg:my-32 mt-16">
        <p className="leading-none lg:text-[5.125rem] text-[2.2rem] font-[500]">
          Self-Custodial wallet for the Move ecosystem
        </p>
        <p className="text-[#afafaf] md:text-lg md:text-sm mt-3">
          Use Martian to store, send, receive tokens and <br /> manage & mint
          NFTs in a secure and friendly way.
          <br /> Currently supports Aptos & Sui.
        </p>
        <div className="my-8 flex w-full items-center px-2">
          <button className=" bg-black border-white text-white custom-btn btndark-3 md:w-44 w-full  pmd:y-2 py-1 px-4 text-sm md:text-md">
            <NavLink to="/connectwallet">
              <span>CONNECT WALLET</span>
            </NavLink>
          </button>
          <button className="mx-2 custom-btn btn-3 md:w-44 w-full md:p-2 py-1 text-sm md:text-md px-4">
            <span>VIEW DOCS</span>
          </button>
        </div>
        <p className="text-[#afafaf] md:block hidden text-lg">
          Available on Chrome, iOS and Google Play coming soon!
        </p>
      </div>
      <div className="ml-20">
        <div className="w-40 lg:w-80 -translate-x-32 absolute shadow-xl">
          <img src={frm} alt="imagem" />
        </div>
        <div className="w-40 lg:w-80 translate-x-10 translate-y-10 shadow-xl">
          <img src={acc} alt="imagem" />
        </div>
      </div>
    </div>
  );
};

export default Banner;
